/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.alert-modal {
    --min-height: 720px;
    --min-width: 95%;

    @media screen and (min-width: 1280px) {
        --min-width: 70%;
    }

}

ion-content {
    --background: #F7F7F7;
}

main {
    padding-top: 70px;
}

* {
    font-family: 'Lato', sans-serif;
}

ion-button {
    --border-radius: 3px !important;
}

ion-item {
    --background: #fff;
}

.pill {
    background: gray;
    border-radius: 20px;
    padding: 5px 8px;
    color: white;
}

.page-title {
    h3 {
        color: #73879C;
        font-size: 1.75rem;
    }
}

@font-face {
    font-family: 'Nasalization';
    src: url('assets/fonts/nasalization-rg.otf') format('truetype');
    /* Add more src entries for other font formats if necessary */
    font-weight: normal;
    font-style: normal;
}

.nasalization-font {
    font-family: 'Nasalization', sans-serif;
}

.alert-wrapper {
    min-width: 350px !important;
}

.alert-radio-label.sc-ion-alert-md {
    text-wrap: balance;
}

.table-wrapper {
    width: 100%;
    border-radius: 5px;
    overflow: hidden !important;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    font-size: 13px;
}

.x_content {
    padding: 0 3px 6px;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    color: #73879C;

    ion-input,
    ion-textarea,
    ion-select {
        color: black;
        --highlight-color-focused: var(--ion-color-secondary);
        --highlight-color-invalid: var(--ion-color-secondary);
        --highlight-color-valid: var(--ion-color-secondary);

        .label-text-wrapper {
            color: var(--ion-color-secondary);

            .label-text {
                white-space: normal;
            }
        }

        .native-input,
        .native-textarea {
            border: 1px solid #73879C;
            margin: 10px 0px;
            padding-left: 10px !important;
        }

        .input-bottom {
            border: 0px;
        }
    }

    ion-select {
        color: var(--ion-color-secondary);
    }

    ion-checkbox {
        padding: 0px 10px;
        color: #73879C;
    }

    ion-checkbox.checkbox-checked::part(container) {
        background-color: var(--ion-color-primary);
        padding: 2px;
    }

    ion-checkbox::part(container) {
        border-radius: 5px;
        background-color: #dadada;
        border: none;
    }
}

.panel_toolbox {
    float: right;
    min-width: 70px;
}

.x_panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.x_title {
    border-bottom: 2px solid #E6E9ED;
    padding: 1px 5px 6px;
    margin-bottom: 10px;
    color: #73879C;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.471;
    height: 40px;

    h2 {
        margin: 5px 0 6px;
        float: left;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
    }
}

.confirmationMinutes {
    ion-item {
        max-width: 321px;
        display: inline-grid;
        --inner-padding-end: 0px !important;

        ion-input {
            width: 292px;
        }

        ion-input .input-wrapper div.label-text-wrapper {
            max-width: 225px;
        }

        ion-input .input-wrapper div.label-text-wrapper div.label-text {
            width: 320px;
            min-width: 240px;
        }

        ion-input .input-wrapper {
            width: 289px;
        }
    }

    ion-label {
        white-space: unset !important;
    }
}

.interval {
    ion-item {
        width: 182px;
        display: inline-grid;
        --inner-padding-end: 0px !important;

        ion-input .input-wrapper div.label-text-wrapper {
            max-width: 120px;
        }

        ion-input .input-wrapper div.label-text-wrapper div.label-text {
            min-width: 90px;
            max-width: 90px;
        }

        ion-input .input-wrapper {
            width: 165px;
        }
    }

    ion-label {
        white-space: unset !important;
    }
}

.alert-condition {
    ion-input .input-wrapper {
        width: 60px;
    }
}

.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: rgba(5, 74, 203);
    --mdc-switch-selected-handle-color: rgba(5, 74, 203);
    --mdc-switch-selected-hover-state-layer-color: rgba(5, 74, 203);
    --mdc-switch-selected-pressed-state-layer-color: rgba(5, 74, 203);
    --mdc-switch-selected-focus-handle-color: rgb(0, 59, 168);
    --mdc-switch-selected-hover-handle-color: rgb(0, 65, 187);
    --mdc-switch-selected-pressed-handle-color: rgb(0, 68, 194);
    --mdc-switch-selected-focus-track-color: rgb(22, 85, 202);
    --mdc-switch-selected-hover-track-color: rgb(46, 100, 202);
    --mdc-switch-selected-pressed-track-color: rgb(46, 100, 202);
    --mdc-switch-selected-track-color: rgb(46, 100, 202);
}

.organization-filter-ion-select {
    font-size: 14px;
    color: var(--ion-color-secondary);
    --highlight-color-focused: var(--ion-color-secondary);
    --highlight-color-invalid: var(--ion-color-secondary);
    --highlight-color-valid: var(--ion-color-secondary);
}